import { datadogRum } from '@datadog/browser-rum'
import { datadogLogs } from '@datadog/browser-logs'
import { getUsername } from '@/api/baseClientUtils'

// DATADOG CONFIG
// DO NOT CHANGE THE BELOW DATADOG CONFIG
const initDatadog = () => {
  // Datadog RUM config
  datadogRum.init({
    applicationId: import.meta.env.VITE_DATADOG_APPLICATION_ID,
    clientToken: import.meta.env.VITE_DATADOG_CLIENT_TOKEN,
    site: 'datadoghq.eu',
    service: 'darwin-web',
    env: import.meta.env.MODE,
    version: '1.0.0',
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
    silentMultipleInit: true,
    trackSessionAcrossSubdomains: true,
    useSecureSessionCookie: true,
    allowFallbackToLocalStorage: true,
  })

  // Datadog Logs config
  datadogLogs.init({
    clientToken: import.meta.env.VITE_DATADOG_CLIENT_TOKEN,
    site: 'datadoghq.eu',
    forwardErrorsToLogs: false,
    sessionSampleRate: 100,
    service: 'darwin-web',
    env: import.meta.env.MODE,
    version: '1.0.0',
    silentMultipleInit: true,
    forwardConsoleLogs: 'all',
    storeContextsAcrossPages: true,
    allowUntrustedEvents: true,
    trackSessionAcrossSubdomains: true,
    useSecureSessionCookie: true,
  })
}

const setDatadogUser = async () => {
  const username = await getUsername()
  datadogRum.setUser({
    email: username,
  })
}

// const trackDatadogAction = (actionName: string, actionData: Record<string, string | object>) => {
// //  if (import.meta.env.MODE !== ENV.DEV) {
// //   datadogRum.addAction(actionName, actionData)
// //  }
// }

const trackAIStrikerClick = () => {
  // trackDatadogAction('ai_alarm_sticker_click', {
  //   type: 'click',
  //   name: 'AI Alarm Striker',
  //   context: {
  //     location: 'Analysis backlog',
  //   },
  // })
}

export { trackAIStrikerClick, initDatadog, setDatadogUser }
